<lis-dialog>
  <ng-container id="header">{{ input.headline | translate }}</ng-container>

  <div>
    <p *ngIf="input.subline" class="typo-h2-bold">{{ input.subline | translate }}</p>
    <p *ngIf="input.content">{{ input.content }}</p>
  </div>

  <ng-container id="footer">
    <div class="flex justify-end space-x-2">
      <button lis-button type="button" [variant]="'outline'" (click)="onCancelClick()">{{ input.buttonCancelLabel | translate }}</button>
      <button lis-button type="button" (click)="onConfirmClick()">{{ input.buttonConfirmLabel | translate }}</button>
    </div>
  </ng-container>
</lis-dialog>
