import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LisDialogConfirmationInput } from '../../types/dialog.type';

@Component({
  templateUrl: './dialog-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogConfirmationComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public input: LisDialogConfirmationInput,
    private dialogRef: MatDialogRef<LisDialogConfirmationInput | null>
  ) {}

  ngOnInit(): void {}

  public onCancelClick(): void {
    this.dialogRef.close();
  }

  public async onConfirmClick(): Promise<void> {
    await this.input.confirmationAction();
    this.dialogRef.close();
  }
}
